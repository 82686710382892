import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import tresses01 from "../../assets/galerie/tresses_femmes/tresses_afro_01.jpg";
import tresses02 from "../../assets/galerie/tresses_femmes/tresses_afro_02.jpg";
import tresses03 from "../../assets/galerie/tresses_femmes/tresses_afro_03.jpg";
import tresses04 from "../../assets/galerie/tresses_femmes/tresses_afro_04.jpg";
import tresses05 from "../../assets/galerie/tresses_femmes/tresses_afro_05.jpg";
import tresses06 from "../../assets/galerie/tresses_femmes/tresses_afro_06.jpg";
import tresses07 from "../../assets/galerie/tresses_femmes/tresses_afro_07.jpg";
import tresses08 from "../../assets/galerie/tresses_femmes/tresses_afro_08.jpg";
import tresses09 from "../../assets/galerie/tresses_femmes/tresses_afro_09.jpg";
import tresses10 from "../../assets/galerie/tresses_femmes/tresses_afro_10.jpg";
import tresses11 from "../../assets/galerie/tresses_femmes/tresses_afro_11.jpg";
import tresses12 from "../../assets/galerie/tresses_femmes/tresses_afro_12.jpg";
import tresses13 from "../../assets/galerie/tresses_femmes/tresses_afro_13.jpg";
import tresses14 from "../../assets/galerie/tresses_femmes/tresses_afro_14.jpg";
import tresses15 from "../../assets/galerie/tresses_femmes/tresses_afro_15.jpg";
import tresses16 from "../../assets/galerie/tresses_femmes/tresses_afro_16.jpg";
import tresses17 from "../../assets/galerie/tresses_femmes/tresses_afro_17.jpg";
import tresses18 from "../../assets/galerie/tresses_femmes/tresses_afro_18.jpg";
import tresses19 from "../../assets/galerie/tresses_femmes/tresses_afro_19.jpg";
import tresses20 from "../../assets/galerie/tresses_femmes/tresses_afro_20.jpg";
import tresses21 from "../../assets/galerie/tresses_femmes/tresses_afro_21.jpg";
import tresses22 from "../../assets/galerie/tresses_femmes/tresses_afro_22.jpg";
import tresses23 from "../../assets/galerie/tresses_femmes/tresses_afro_23.jpg";
import tresses24 from "../../assets/galerie/tresses_femmes/tresses_afro_24.jpg";
import tresses25 from "../../assets/galerie/tresses_femmes/tresses_afro_25.jpg";
import tresses26 from "../../assets/galerie/tresses_femmes/tresses_afro_26.jpg";
import tresses27 from "../../assets/galerie/tresses_femmes/tresses_afro_27.jpg";
import tresses28 from "../../assets/galerie/tresses_femmes/tresses_afro_28.jpg";
import tresses29 from "../../assets/galerie/tresses_femmes/tresses_afro_29.jpg";
import tresses30 from "../../assets/galerie/tresses_femmes/tresses_afro_30.jpg";
import tresses31 from "../../assets/galerie/tresses_femmes/tresses_afro_31.jpg";
import tresses32 from "../../assets/galerie/tresses_femmes/tresses_afro_32.jpg";
import tresses33 from "../../assets/galerie/tresses_femmes/tresses_afro_33.jpg";
import tresses34 from "../../assets/galerie/tresses_femmes/tresses_afro_34.jpg";
import tresses35 from "../../assets/galerie/tresses_femmes/tresses_afro_35.jpg";
import tresses36 from "../../assets/galerie/tresses_femmes/tresses_afro_36.jpg";
import tresses37 from "../../assets/galerie/tresses_femmes/tresses_afro_37.jpg";
import tresses38 from "../../assets/galerie/tresses_femmes/tresses_afro_38.jpg";
import tresses39 from "../../assets/galerie/tresses_femmes/tresses_afro_39.jpg";
import tresses40 from "../../assets/galerie/tresses_femmes/tresses_afro_40.jpg";
import tresses41 from "../../assets/galerie/tresses_femmes/tresses_afro_41.jpg";
import tresses42 from "../../assets/galerie/tresses_femmes/tresses_afro_42.jpg";
import tresses43 from "../../assets/galerie/tresses_femmes/tresses_afro_43.jpg";
import tresses44 from "../../assets/galerie/tresses_femmes/tresses_afro_44.jpg";
import tresses45 from "../../assets/galerie/tresses_femmes/tresses_afro_45.jpg";
import tresses46 from "../../assets/galerie/tresses_femmes/tresses_afro_46.jpg";
import tresses47 from "../../assets/galerie/tresses_femmes/tresses_afro_47.jpg";
import tresses48 from "../../assets/galerie/tresses_femmes/tresses_afro_48.jpg";
import tresses49 from "../../assets/galerie/tresses_femmes/tresses_afro_49.jpg";
import tresses50 from "../../assets/galerie/tresses_femmes/tresses_afro_50.jpg";
import tresses51 from "../../assets/galerie/tresses_femmes/tresses_afro_51.jpg";
import tresses52 from "../../assets/galerie/tresses_femmes/tresses_afro_52.jpg";
import tresses53 from "../../assets/galerie/tresses_femmes/tresses_afro_53.jpg";
import tresses54 from "../../assets/galerie/tresses_femmes/tresses_afro_54.jpg";
import tresses55 from "../../assets/galerie/tresses_femmes/tresses_afro_55.jpg";
import tresses56 from "../../assets/galerie/tresses_femmes/tresses_afro_56.jpg";
import tresses57 from "../../assets/galerie/tresses_femmes/tresses_afro_57.jpg";
import tresses58 from "../../assets/galerie/tresses_femmes/tresses_afro_58.jpg";
import tresses59 from "../../assets/galerie/tresses_femmes/tresses_afro_59.jpg";
import tresses60 from "../../assets/galerie/tresses_femmes/tresses_afro_60.jpg";
import tresses61 from "../../assets/galerie/tresses_femmes/tresses_afro_61.jpg";
import tresses62 from "../../assets/galerie/tresses_femmes/tresses_afro_62.jpg";
import tresses63 from "../../assets/galerie/tresses_femmes/tresses_afro_63.jpg";
import tresses64 from "../../assets/galerie/tresses_femmes/tresses_afro_64.jpg";
import tresses65 from "../../assets/galerie/tresses_femmes/tresses_afro_65.jpg";
import tresses66 from "../../assets/galerie/tresses_femmes/tresses_afro_66.jpg";


const GalleryTresses = () => {
  const imagesTresses = [
      {
        original: tresses01,
        thumbnail: tresses01,
        description: "Tresses 1",
      },
      {
        original: tresses02,
        thumbnail: tresses02,
        description: "Tresses 2",
      },
      {
        original: tresses03,
        thumbnail: tresses03,
        description: "Tresses 3",
      },
      {
        original: tresses04,
        thumbnail: tresses04,
        description: "Tresses 4",
      },
      {
        original: tresses05,
        thumbnail: tresses05,
        description: "Tresses 5",
      },
      {
        original: tresses06,
        thumbnail: tresses06,
        description: "Tresses 6",
      },
      {
        original: tresses07,
        thumbnail: tresses07,
        description: "Tresses 7",
      },
      {
        original: tresses08,
        thumbnail: tresses08,
        description: "Tresses 8",
      },
      {
        original: tresses09,
        thumbnail: tresses09,
        description: "Tresses 9",
      },
      {
        original: tresses10,
        thumbnail: tresses10,
        description: "Tresses 10",
      },
      {
        original: tresses11,
        thumbnail: tresses11,
        description: "Tresses 11",
      },
      {
        original: tresses12,
        thumbnail: tresses12,
        description: "Tresses 12",
      },
      {
        original: tresses13,
        thumbnail: tresses13,
        description: "Tresses 13",
      },
      {
        original: tresses14,
        thumbnail: tresses14,
        description: "Tresses 14",
      },
      {
        original: tresses15,
        thumbnail: tresses15,
        description: "Tresses 15",
      },
      {
        original: tresses16,
        thumbnail: tresses16,
        description: "Tresses 16",
      },
      {
        original: tresses17,
        thumbnail: tresses17,
        description: "Tresses 17",
      },
      {
        original: tresses18,
        thumbnail: tresses18,
        description: "Tresses 18",
      },
      {
        original: tresses19,
        thumbnail: tresses19,
        description: "Tresses 19",
      },
      {
        original: tresses20,
        thumbnail: tresses20,
        description: "Tresses 20",
      },
      {
        original: tresses21,
        thumbnail: tresses21,
        description: "Tresses 21",
      },
      {
        original: tresses22,
        thumbnail: tresses22,
        description: "Tresses 22",
      },
      {
        original: tresses23,
        thumbnail: tresses23,
        description: "Tresses 23",
      },
      {
        original: tresses24,
        thumbnail: tresses24,
        description: "Tresses 24",
      },
      {
        original: tresses25,
        thumbnail: tresses25,
        description: "Tresses 25",
      },
      {
        original: tresses26,
        thumbnail: tresses26,
        description: "Tresses 26",
      },
      {
        original: tresses27,
        thumbnail: tresses27,
        description: "Tresses 27",
      },
      {
        original: tresses28,
        thumbnail: tresses28,
        description: "Tresses 28",
      },
      {
        original: tresses29,
        thumbnail: tresses29,
        description: "Tresses 29",
      },
      {
        original: tresses30,
        thumbnail: tresses30,
        description: "Tresses 30",
      },
      {
        original: tresses31,
        thumbnail: tresses31,
        description: "Tresses 31",
      },
      {
        original: tresses32,
        thumbnail: tresses32,
        description: "Tresses 32",
      },
      {
        original: tresses33,
        thumbnail: tresses33,
        description: "Tresses 33",
      },
      {
        original: tresses34,
        thumbnail: tresses34,
        description: "Tresses 34",
      },
      {
        original: tresses35,
        thumbnail: tresses35,
        description: "Tresses 35",
      },
      {
        original: tresses36,
        thumbnail: tresses36,
        description: "Tresses 36",
      },
      {
        original: tresses37,
        thumbnail: tresses37,
        description: "Tresses 37",
      },
      {
        original: tresses38,
        thumbnail: tresses38,
        description: "Tresses 38",
      },
      {
        original: tresses39,
        thumbnail: tresses39,
        description: "Tresses 39",
      },
      {
        original: tresses40,
        thumbnail: tresses40,
        description: "Tresses 40",
      },
      {
        original: tresses41,
        thumbnail: tresses41,
        description: "Tresses 41",
      },
      {
        original: tresses42,
        thumbnail: tresses42,
        description: "Tresses 42",
      },
      {
        original: tresses43,
        thumbnail: tresses43,
        description: "Tresses 43",
      },
      {
        original: tresses44,
        thumbnail: tresses44,
        description: "Tresses 44",
      },
      {
        original: tresses45,
        thumbnail: tresses45,
        description: "Tresses 45",
      },
      {
        original: tresses46,
        thumbnail: tresses46,
        description: "Tresses 46",
      },
      {
        original: tresses47,
        thumbnail: tresses47,
        description: "Tresses 47",
      },
      {
        original: tresses48,
        thumbnail: tresses48,
        description: "Tresses 48",
      },
      {
        original: tresses49,
        thumbnail: tresses49,
        description: "Tresses 49",
      },
      {
        original: tresses50,
        thumbnail: tresses50,
        description: "Tresses 50",
      },
      {
        original: tresses51,
        thumbnail: tresses51,
        description: "Tresses 51",
      },
      {
        original: tresses52,
        thumbnail: tresses52,
        description: "Tresses 52",
      },
      {
        original: tresses53,
        thumbnail: tresses53,
        description: "Tresses 53",
      },
      {
        original: tresses54,
        thumbnail: tresses54,
        description: "Tresses 54",
      },
      {
        original: tresses55,
        thumbnail: tresses55,
        description: "Tresses 55",
      },
      {
        original: tresses56,
        thumbnail: tresses56,
        description: "Tresses 56",
      },
      {
        original: tresses57,
        thumbnail: tresses57,
        description: "Tresses 57",
      },
      {
        original: tresses58,
        thumbnail: tresses58,
        description: "Tresses 58",
      },
      {
        original: tresses59,
        thumbnail: tresses59,
        description: "Tresses 59",
      },
      {
        original: tresses60,
        thumbnail: tresses60,
        description: "Tresses 60",
      },
      {
        original: tresses61,
        thumbnail: tresses61,
        description: "Tresses 61",
      },
      {
        original: tresses62,
        thumbnail: tresses62,
        description: "Tresses 62",
      },
      {
        original: tresses63,
        thumbnail: tresses63,
        description: "Tresses 63",
      },
      {
        original: tresses64,
        thumbnail: tresses64,
        description: "Tresses 64",
      },
      {
        original: tresses65,
        thumbnail: tresses65,
        description: "Tresses 65",
      },
      {
        original: tresses66,
        thumbnail: tresses66,
        description: "Tresses 66",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de tresses";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesTresses} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryTresses;